//Robert James Fischer vs Reuben Fine

const movesList= [[ "e2",  "e4"], [ "e7",  "e5"],[ "g1",  "f3"],
[ "b8",  "c6"], [ "f1",  "c4"], [ "f8",  "c5"],  [ "b2",  "b4"],
  [ "c5",  "b4"],  [ "c2",  "c3"],  [ "b4",  "a5"], [ "d2",  "d4"], [ "e5",  "d4"],
["castle",  "WK"], [ "d4",  "c3"], [ "d1",  "b3"], [ "d8",  "e7"],
[ "b1",  "c3"],[ "g8",  "f6"], [ "c3",  "d5"], [ "f6",  "d5"],[ "e4",  "d5"],
[ "c6",  "e5"],[ "f3",  "e5"],[ "e7",  "e5"], ["c1", "b2"],[ "e5",  "g5"],
[ "h2",  "h4"],[ "g5",  "h4"],[ "b2",  "g7"], [ "h8",  "g8"],[ "f1",  "e1"], 
[ "e8",  "d8"], [ "b3",  "g3"], [ "gameOver",  "gameOver"]];

const theApplause= [[ "c2",  "c4"],[ "e7",  "e6"],[ "g1",  "f3"],
[ "d7",  "d5"],[ "d2",  "d4"], [ "g8",  "f6"], [ "b1",  "c3"],[ "f8",  "e7"],[ "c1",  "g5"], [ "castle",  "BK"],
[ "e2",  "e3"],[ "h7",  "h6"],[ "g5",  "h4"],[ "b7",  "b6"],[ "c4",  "d5"],[ "f6",  "d5"],[ "h4",  "e7"],
[ "d8",  "e7"],[ "c3",  "d5"],[ "e6",  "d5"], [ "a1",  "c1"],[ "c8",  "e6"],[ "d1",  "a4"],[ "c7",  "c5"],
[ "a4",  "a3"],[ "f8",  "c8"],[ "f1",  "b5"], [ "a7",  "a6"],[ "d4",  "c5"],[ "b6",  "c5"], [ "castle",  "WK"],
[ "a8",  "a7"], [ "b5",  "e2"],
[ "b8",  "d7"], [ "f3",  "d4"],[ "e7",  "f8"], [ "d4",  "e6"],[ "f7",  "e6"],[ "e3",  "e4"], [ "d5",  "d4"], 
[ "f2",  "f4"],[ "f8",  "e7"], [ "e4",  "e5"],[ "c8",  "b8"], [ "e2",  "c4"], [ "g8",  "h8"], [ "a3",  "h3"],
[ "d7",  "f8"],[ "b2",  "b3"], [ "a6",  "a5"], [ "f4",  "f5"],[ "e6",  "f5"], [ "f1",  "f5"],[ "f8",  "h7"],
[ "c1",  "f1"],[ "e7",  "d8"],[ "h3",  "g3"],[ "a7",  "e7"],[ "h2",  "h4"], [ "b8",  "b7"], [ "e5",  "e6"], 
[ "b7",  "c7"], [ "g3",  "e5"],[ "d8",  "e8"], [ "a2",  "a4"],[ "e8",  "d8"],[ "f1",  "f2"],[ "d8",  "e8"],
[ "f2",  "f3"],[ "e8",  "d8"],[ "c4",  "d3"],[ "d8",  "e8"],[ "e5",  "e4"],[ "h7",  "f6"],[ "f5",  "f6"],
[ "g7",  "f6"],[ "f3",  "f6"],[ "h8",  "g8"],[ "d3",  "c4"],[ "g8",  "h8"],[ "e4",  "f4"],[ "gameOver",  "gameOver"]]

const kasparovDB = [["e2", "e4"],["c7", "c6"],["d2", "d4"],["d7", "d5"],["b1", "c3"],["d5", "e4"],["c3", "e4"],["b8", "d7"],["e4", "g5"],
["g8", "f6"],["f1", "d3"],["e7", "e6"],["g1", "f3"],["h7", "h6"],["g5", "e6"],["d8", "e7"],["castle", "WK"],["f7", "e6"],["d3", "g6"],
["e8", "d8"],["c1", "f4"],["b7", "b5"],["a2", "a4"],["c8", "b7"],["f1", "e1"],["f6", "d5"],["f4", "g3"],["d8", "c8"],["a4", "b5"],
["c6", "b5"],["d1", "d3"],["b7", "c6"],["g6", "f5"],["e6", "f5"],["e1", "e7"],["f8", "e7"],["c2", "c4"],["gameOver", "gameOver"]]

const carlsen_giri = [['c2', 'c4'],['e7', 'e5'],['b1', 'c3'],['g8', 'f6'],['g1', 'f3'],['b8', 'c6'],['g2', 'g3'],['d7', 'd5'],
['c4', 'd5'],['f6', 'd5'],['f1', 'g2'],['f8', 'c5'],['castle', 'WK'],['castle', 'BK'],['d2', 'd3'],['h7', 'h6'],
['c3', 'd5'],['d8', 'd5'],['a2', 'a3'],['a7', 'a5'],['c1', 'd2'],['d5', 'e6'],['a1', 'c1'],['e6', 'e7'],['d2', 'c3'],
['c6', 'd4'],['e2', 'e3'],['d4', 'f3'],['d1', 'f3'],['c5', 'd6'],['f3', 'h5'],['c7', 'c6'],['f2', 'f4'],['e5', 'f4'],
['g3', 'f4'],['e7', 'e3'],['g1', 'h1'],['f8', 'd8'],['c1', 'e1'],['e3', 'c5'],['f4', 'f5'],['d6',  'f8'],['g2', 'e4'],['d8', 'd5'],
['f1', 'f3'],['b7', 'b5'],['e1', 'g1'],['a8', 'a7'],['c3', 'f6'],['g7', 'g6'],['h5', 'h3'],['d5', 'd6'],['h3', 'h4'],
['d6', 'f6'],['h4', 'f6'],['f8', 'e7'],['f6', 'c6'],['c5', 'c6'],['e4', 'c6'],['g8', 'g7'],['f5', 'g6'],['f7', 'g6'],
['d3', 'd4'],['a5', 'a4'],['d4', 'd5'],['b5', 'b4'],['c6', 'e8'],['e7', 'g5'],['h2', 'h4'],['g5', 'h4'],['g1', 'g6'],
['g7', 'h7'],['g6', 'c6'],['c8', 'g4'],['f3', 'f4'],['a7', 'g7'],['gameOver', 'gameOver']];

const carlsen_ivanchuk = [['d2', 'd4'],['g8', 'f6'],['c1', 'g5'],['c7', 'c5'],['g5', 'f6'],['g7', 'f6'],['d4', 'd5'],
['d8', 'b6'],['d1', 'c1'],['f6', 'f5'],['c2', 'c4'],['d7', 'd6'],['b1', 'c3'],['b8', 'd7'],['g2', 'g3'],
['f8', 'g7'],['g1', 'f3'],['d7', 'f6'],['f3', 'd2'],['f6', 'e4'],['d2', 'e4'],['f5', 'e4'],['f1', 'g2'],
['b6', 'b4'],['castle', 'WK'],['b4', 'c4'],['g2', 'e4'],['h7', 'h5'],['h2', 'h4'],['c8', 'g4'],['f1', 'e1'],
['castle', 'BQ'],['a1','b1'],['c8', 'b8'],['e4', 'g2'],['h8', 'g8'],['c1', 'e3'],['g7', 'f6'],['c3', 'e4'],
['f6', 'd4'],['e3', 'd2'],['c4', 'a2'],['d2', 'f4'],['g8', 'f8'],['b2', 'b4'],['d4', 'e5'],['f4', 'e3'],
['c5', 'c4'],['e4', 'c3'],['a2', 'a6'],['e3', 'd2'],['d8', 'c8'],['e2', 'e3'],['g4', 'f5'],['b1', 'a1'],
['a6', 'b6'],['a1', 'a4'],['f5', 'd3'],['e1', 'a1'],['a7', 'a6'],['g2', 'f3'],['f8', 'g8'],['g1', 'g2'],['f7', 'f5'],
['f3', 'h5'],['c8', 'f8'],['b4', 'b5'],['f5', 'f4'],['e3', 'f4'],['e5', 'f4'],['d2', 'e1'],['f4', 'g3'],['f2', 'g3'],
['f8', 'f1'],['e1', 'e2'],['b6', 'g1'],[ "gameOver",  "gameOver"]];

const nakamura_bareev = [['g1','f3'],['d7','d5'],['d2','d4'],['c7','c6'],['c1','f4'],['d8','b6'],['b2','b3'],['g8','f6'],
['e2','e3'],['f6','h5'],['f4','g5'],['h7','h6'],['g5','h4'],['b8','d7'],['f3','e5'],['d7','e5'],['d1','h5'],
['e5','g4'],['h4','g3'],['g7','g6'],['h5','h4'],['f8','g7'],[ "gameOver",  "gameOver"]]

const morphy_anderson = [["e2", "e4"],["c7", "c5"],["d2", "d4"],["c5", "d4"],["g1", "f3"], ["b8", "c6"],["f3", "d4"],
["e7", "e6"],["d4", "b5"],["d7", "d6"],["c1", "f4"],["e6", "e5"],["f4", "e3"],["f7", "f5"],["b1", "c3"],
["f5", "f4"],["c3", "d5"],["f4", "e3"],["b5", "c7"],["e8", "f7"],["d1", "f3"],["g8", "f6"],["f1", "c4"],["c6", "d4"],
["d5", "f6"], ["d6", "d5"],["c4", "d5"],["f7", "g6"],["f3", "h5"],["g6", "f6"],["f2", "e3"],["d4", "c2"],["e1", "e2"],
["gameOver", "gameOver"]];

const kasparov_kramnik = [["d2", "d4"], ["g8", "f6"],["c2", "c4"],["g7", "g6"],["b1", "c3"], ["f8", "g7"],["e2", "e4"],
["d7", "d6"], ["g1", "f3"],["castle", "BK"], ["f1", "e2"],["e7", "e5"],["d4", "d5"],["a7", "a5"],["c1", "g5"],["h7", "h6"],
["g5", "h4"],["b8", "a6"],["castle", "WK"],["c8", "d7"],["f3", "d2"],["a6", "c5"],["b2", "b3"],["f6", "e4"],["h4", "d8"],
["e4", "c3"],["d1", "e1"],["f8", "d8"],["a1", "c1"],["c3", "a2"],["c1", "a1"],["a2", "b4"],["e2", "d1"],["e5", "e4"],
["a1", "b1"],["d8", "e8"],["e1", "e3"],["f7", "f5"],["h2", "h4"],["e8", "f8"],["g2", "g3"],["a8", "e8"],["g1", "g2"]
,["b4", "d3"],["f1", "g1"],["f5", "f4"],["g3 ", "f4"],["f8", "f4"],["h4", "h5"],["g6", "g5"],["g1", "f1"],["f4", "h4"]
,["f1", "h1"],["h4", "f4"],["h1", "f1"],["e8", "f8"],["f2", "f3"],["f4", "h4"],["f3", "e4"],["d3", "f4"],["g2", "g1"]
,["c5", "d3"],["e4", "e5"],["d3", "e5"],["b1", "c1"],["h4", "h3"],["d2", "f3"],["g5", "g4"],["f3", "e5"],["h3", "e3"]
,["e5", "d7"],["f4", "h3"],["g1", "g2"],["f8", "f1"],["g2", "f1"],["g4", "g3"],["f1", "g2"],["h3", "f4"]
,["gameOver", "gameOver"]]

var dubov_simon = [["e2","e4"],["c7", "c5"],["g1", "f3"],["d7", "d6"],["d2", "d4"],["c5", "d4"],["f3", "d4"]
,["g8", "f6"],["b1", "c3"],["b8", "c6"],["c1", "g5"],["e7", "e6"],["d1", "d3"],["c8", "d7"],["castle", "WQ"]
,["a7", "a6"],["f2", "f4"],["f8", "e7"],["d4", "c6"],["d7", "c6"],["c1", "b1"],["castle", "BK"],
["h2", "h4"],["b7", "b5"],["g5", "f6"],["e7", "f6"],["c3", "e2"],["d8", "a5"],["e2", "d4"],["a8", "c8"],
["g2", "g4"],["f6", "d4"],["d3", "d4"],["e6", "e5"],["d4", "e3"],["e5", "f4"],["e3", "f4"],["c6", "d7"],
["h4", "h5"],["d7", "e6"],["a2", "a3"],["b5", "b4"],["h5", "h6"],["b4", "a3"],["h6", "g7"],["e6", "a2"],
["b1", "a2"],["a3", "b2"],["a2", "b2"],["a5", "c3"],["b2", "a2"],["c3", "c2"],["a2", "a1"],["c2", "d1"],["gameOver", "gameOver"] ]

var carlsen_duda=[["d2","d4"],["g8","f6"],["c2","c4"],["e7","e6"],["b1","c3"],["d7","d5"],["c4","d5"],["f6","d5"],
["g1","f3"],["c7","c5"],["e2","e3"],["c5","d4"],["e3","d4"],["d5","c3"],["b2","c3"],["d8","c7"],["a1","b1"],
["b8","d7"],["f1","d3"],["c7","c3"],["e1","f1"],["f8","e7"],["h2","h4"],["castle","BK"],["h1","h3"],["d7","f6"],
["f3","e5"],["c3","a5"],["h3","g3"],["g8","h8"],["c1","g5"],["h7","h6"],["g5","h6"],["g7","h6"],["d1","f3"],["gameOver", "gameOver"]]

var spassky_larsen=[["b2","b3"],["e7","e5"],["c1","b2"],[ "b8",  "c6"],["c2","c4"], [ "g8",  "f6"],
["g1", "f3"],["e5","e4"],["f3","d4"],["f8","c5"],["d4","c6"],["d7","c6"],["e2","e3"],["c8","f5"],
["d1","c2"],["d8","e7"],["f1","e2"],["castle","BQ"],["f2","f4"],["f6","g4"],["g2","g3"],["h7","h5"],
["h2","h3"],["h5","h4"],["h3","g4"],["h4","g3"],["h1","g1"],["h8","h1"],["g1","h1"],["g3","g2"],
["h1","f1"],["e7","h4"],["e1","d1"],["g2","f1"],["e2","f1"],["f5","g4"],["f1","e2"],["h4","h1", "mate"],["gameOver", "gameOver"]]

var caruana_ponomariov=[["e2","e4"],["e7","e5"],["g1","f3"],["g8","f6"],["f3","e5"],["d7","d6"],["e5","f3"],
["f6","e4"],["b1","c3"],["e4","c3"],["d2","c3"],["f8","e7"],["c1","e3"],["b8","c6"],["d1","d2"],["c8","e6"],
["castle","WQ"],["d8","d7"],["c1","b1"],["e7","f6"],["h2","h3"],["h7","h6"],["b2","b3"],["a7","a6"],
["g2","g4"],["castle","BQ"],["f1","g2"],["g7","g5"],["f3","d4"],["c6","d4"],["c3","d4"],["d6","d5"],
["f2","f4"],["g5","f4"],["e3","f4"],["h6","h5"],["g4","g5"],["f6","g7"],["d1","e1"],["h5","h4"],["f4","e5"],
["d8","g8"],["d2","f4"],["d7","d8"],["g2","f1"],["c8","b8"],["f1","d3"],["e6","c8"],["b1","b2"],["g7","e5"],
["e1","e5"],["g8","g7"],["a2","a4"],["a6","a5"],["b2","a2"],["b8","a7"],["f4","d2"],["a7","b8"],
["d2","f4"],["b8","a7"],["h1","e1"],["c8","h3"],["e1","h1"],["h3","c8"],["h1","h4"],["h8","h4"],["f4","h4"],
["b7","b6"],["h4","h6"],["g7","g8"],["h6","c6"],["c8","e6"],["g5","g6"],["g8","g7"],["g6","f7"],["e6","f7"],
["e5","e7"],["d8","e7"],["d3","a6"],["a7","a6"],["c6","a8", "mate"],["gameOver", "gameOver"]]

var ivanchuk_kasparov=[["e2","e4"],["e7","e6"],["d2","d4"],["d7","d5"],["b1","c3"],["f8","b4"],["e4","e5"],
["b7","b6"],["a2","a3"],["b4","f8"],["g1","f3"],["g8","e7"],["h2","h4"],["h7","h6"],["h4","h5"],["a7","a5"],
["f1","b5"],["c7","c6"],["b5","a4"],["b8","d7"],["c3","e2"],["b6","b5"],["a4","b3"],["c6","c5"],["c2","c3"],["e7","c6"],["castle","WK"],
["d8","c7"],["f1","e1"],["c5","c4"],["b3","c2"],["d7","b6"],["c1","f4"],["f8","e7"],["f4","g3"],["a8","b8"],
["f3","h2"],["c7","d8"],["h2","g4"],["b5","b4"],["a3","b4"],["a5","b4"],["c3","b4"],["c6","b4"],["c2","b1"],
["c8","d7"],["b2","b3"],["b8","a8"],["a1","a8"],["d8","a8"],["b3","c4"],["b6","c4"],["e2","c1"],["d7","a4"],
["d1","e2"],["a8","a7"],["g4","e3"],["a7","d4"],["e3","c4"],["d5","c4"],["e2","f1"],["castle","BK"],["gameOver", "gameOver"]]

var aronian_dubov = [["e2","e4"],["c7","c5"],["g1","f3"],["d7","d6"],["c2","c3"],["g8","f6"],["f1","e2"],
["b8","d7"],["d2","d3"],["g7","g6"],["castle","WK"],["f8","g7"],["c1","e3"],["castle","BK"],["b1","d2"],["b7","b6"],
["d3","d4"],["c8","b7"],["d4","d5"],["f8","e8"],["a2","a4"],["a7","a6"],["f1","e1"],["d8","c7"],["e2","c4"],["f6","g4"],
["e3","f4"],["g4","e5"],["f3","e5"],["d7","e5"],["c4","f1"],["e7","e6"],["d5","e6"],["e8","e6"],["d2","c4"],["a8","e8"],
["f2","f3"],["b6","b5"],["a4","b5"],["a6","b5"],["c4","e5"],["d6","e5"],["f4","e3"],["c5","c4"],["b2","b3"],["c4","b3"],
["d1","b3"],["b7","c6"],["c3","c4"],["b5","c4"],["f1","c4"],["e6","e7"],["a1","a7"],["c6","b7"],["c4","a6"],["c7","a5"],
["e1","c1"],["b7","a6"],["a7","e7"],["e8","e7"],["b3","b8"],["g7","f8"],["e3","h6"],["a6","c8"],["b8","c8"],["gameOver", "gameOver"]]



export {movesList, theApplause, kasparovDB, carlsen_giri, carlsen_ivanchuk, nakamura_bareev, 
        morphy_anderson, kasparov_kramnik, dubov_simon, carlsen_duda, spassky_larsen, caruana_ponomariov, ivanchuk_kasparov, aronian_dubov};

//var kasparovDB: move[] = [[]]
